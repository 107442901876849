import React from 'react';
import { CustomGridColumns } from '~/components/CustomDataGrid/protocols';
import { calculaValorTabelaLiquida } from '~/pages/EntradaNFE/functions/Calculos/ValorTabelaLiquida';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
// import { calculaValorTabelaLiquida } from '~/pages/EntradaNFE/functions/Calculos';

export const variaveisColumns: CustomGridColumns[] = [
  {
    field: 'val_tot_desp_acess',
    headerName: 'Tot. Desp. Acessória',
    width: 170,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_desp_acess } = row;
      return <span>{formatCurrencyAsText(val_tot_desp_acess)}</span>;
    },
  },
  {
    field: 'val_tot_acrescimo',
    headerName: 'Tot. Acrescimo',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_acrescimo } = row;
      return <span>{formatCurrencyAsText(val_tot_acrescimo)}</span>;
    },
  },
  {
    field: 'val_tot_desconto',
    headerName: 'Tot. Desconto',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_desconto } = row;
      return <span>{formatCurrencyAsText(val_tot_desconto)}</span>;
    },
  },
  {
    field: 'val_tot_frete',
    headerName: 'Tot. Frete (NF)',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_frete } = row;
      return <span>{formatCurrencyAsText(val_tot_frete)}</span>;
    },
  },
  {
    field: 'val_tot_venda_varejo',
    headerName: 'Venda Varejo',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_venda_varejo } = row;
      return <span>{formatCurrencyAsText(val_tot_venda_varejo)}</span>;
    },
  },
  {
    field: 'val_liq_item',
    headerName: 'Valor Liquido',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tabela_liq, qtd_entrada } = row;
      const total = val_tabela_liq / qtd_entrada;
      return <span>{formatCurrencyAsText(total)}</span>;
    },
  },
  {
    field: 'val_tabela_liq',
    headerName: 'Tot. Liquido',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tabela_liq } = row;
      return <span>{formatCurrencyAsText(val_tabela_liq)}</span>;
    },
  },
  {
    field: 'val_tot_ipi',
    headerName: 'Tot. IPI',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_ipi } = row;
      return <span>{formatCurrencyAsText(val_tot_ipi)}</span>;
    },
  },
  {
    field: 'val_tot_produto',
    headerName: 'Tot. Produto',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell(row) {
      const { val_tot_produto } = row;
      return <span>{formatCurrencyAsText(val_tot_produto)}</span>;
    },
  },
];
